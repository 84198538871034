
import { defineComponent, onMounted } from "vue";
import CreditNotesTable from "@/components/finance/CreditNotesTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "finance-credit-notes",
  components: {
    CreditNotesTable
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Credit Notes", ["Finance"]);
    });
  },
  created() {
    //Set page title
    document.title = "Finance: Credit Note List | " + process.env.VUE_APP_TITLE;
  }
});
