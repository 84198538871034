
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "finance-credit-notes-table-component",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      creditNotes: [
        {
          underwriter: { name: "" },
          serialNumber: "",
          clientName: "",
          amount: 0,
          status: "",
          narration: "",
          transactionDate: "",
          publicId: "",
          policyPublicId: "",
          netPremium: 0,
        },
      ],
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();

    return {
      useReusableNavigation,
      reinitialization,
      displayDatatable,
    };
  },
  mounted() {
    this.getCreditNotes();
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    getCreditNotes() {
      //Fetch list of all policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.FINANCE_CREDIT_NOTES_ROUTE)
          .then(({ data }) => {
            this.creditNotes = data.data;

            if (this.creditNotes) {
              this.displayDatatable(["basicExample"]);
            }
          })
          .catch(function(error) {
            console.log(error);
            // variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    removeUnderscore(string) {
      return variables.removeUnderscore(string);
    },
  },
});
